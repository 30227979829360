(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.core'], factory) :
    factory();
})((function () {
    (function( window, undefined$1 ) {
        kendo.cultures["es-BO"] = {
            name: "es-BO",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": ".",
                ".": ",",
                groupSize: [3],
                percent: {
                    pattern: ["-n %","n %"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "Bolivian Boliviano",
                    abbr: "BOB",
                    pattern: ["-$n","$n"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "Bs"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["domingo","lunes","martes","miércoles","jueves","viernes","sábado"],
                        namesAbbr: ["dom.","lun.","mar.","mié.","jue.","vie.","sáb."],
                        namesShort: ["DO","LU","MA","MI","JU","VI","SA"]
                    },
                    months: {
                        names: ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"],
                        namesAbbr: ["ene.","feb.","mar.","abr.","may.","jun.","jul.","ago.","sep.","oct.","nov.","dic."]
                    },
                    AM: ["a. m.","a. m.","A. M."],
                    PM: ["p. m.","p. m.","P. M."],
                    patterns: {
                        d: "d/M/yyyy",
                        D: "dddd, d 'de' MMMM 'de' yyyy",
                        F: "dddd, d 'de' MMMM 'de' yyyy HH:mm:ss",
                        g: "d/M/yyyy HH:mm",
                        G: "d/M/yyyy HH:mm:ss",
                        m: "d 'de' MMMM",
                        M: "d 'de' MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "HH:mm",
                        T: "HH:mm:ss",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM 'de' yyyy",
                        Y: "MMMM 'de' yyyy"
                    },
                    "/": "/",
                    ":": ":",
                    firstDay: 1
                }
            }
        };
    })();

}));
